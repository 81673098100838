<!-- 更改后使用 -->
<template>
  <div class="pageContent">
    <!-- 资产折合 -->
    <section class="mainAccout">
      <div class="centerBox">
        <div class="leftMain">
          <p class="title">总资产折合</p>
          <p class="num">
            <span style="font-size:32px">{{ totalAssets | toThousands }}</span>
          </p>
        </div>
        <div class="rightMain">
          <!-- <el-button class="mainBtn" type="primary" plain @click="goTransfer()">划转</el-button> -->
          <!-- <el-button class="mainBtn" type="primary" plain @click="goSignList()">解约</el-button> -->
          <el-button class="mainBtn" type="primary" plain @click="goOutInt()"
            >出入金管理</el-button
          >
          <el-button class="mainBtn" type="primary" plain @click="goInfo()"
            >信息申报</el-button
          >
          <el-button class="mainBtn" type="primary" plain @click="goSign()"
            >签约</el-button
          >

          <el-button class="mainBtn" type="primary" plain @click="goSignList()"
            >银行签约信息</el-button
          >
        </div>
      </div>
    </section>

    <!-- 账户tabs -->
    <section class="account">
      <div class="wrapper">
        <div class="accTabs" v-if="BaseMarkets.length != 0">
          <div class="tabsStyle">
            <div class="zjWrap">
              <div
                class="tabStyle"
                v-for="(item, ind) in BaseMarkets"
                :key="ind"
                @click="handleClick(item)"
              >
                <p
                  class="titleCs"
                  :class="
                    String(item.marketId) == activeName ? 'activeItemStyle' : ''
                  "
                >
                  {{ item.accountName }}
                </p>
                <p
                  :class="String(item.marketId) == activeName ? 'line' : ''"
                ></p>
              </div>
            </div>
            <div class="zjStyle">
              <div class="zjItem" v-if="item.marketId != 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">账户资金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      item.balance - item.frozenFunds > 0
                        ? item.balance - item.frozenFunds
                        : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId != 28">
                <div>
                  <img
                    src="../../assets/images/asset/djzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">冻结资金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      item.frozenFunds > 0 ? item.frozenFunds : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">当前账户权益</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(newBalance) > 0
                        ? Number(Number(newBalance).toFixed(2))
                        : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">资金安全率</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(myAccount.risk) > 99.9999 ||
                      Number(myAccount.occupyMargin) === 0
                        ? "9999.99"
                        : (Number(myAccount.risk) * 100).toFixed(2)
                    }}%
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">上日权益</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(myAccount.lastBalance) > 0
                        ? Number(Number(myAccount.lastBalance).toFixed(2))
                        : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">订货盈亏</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">{{ myAccount.floatPl | priceFormat }}</p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">转让盈亏</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{ myAccount.transferClosePl | priceFormat }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">交收盈亏</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{ myAccount.deliveryClosePl | priceFormat }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">可用资金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(myAccount.avilibleMargin) > 0
                        ? Number(Number(myAccount.avilibleMargin).toFixed(2))
                        : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">占用履约订金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(Number(myAccount.occupyMargin).toFixed(2))
                        | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">占用履约订金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(Number(myAccount.occupyMargin).toFixed(2))
                        | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">冻结资金</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      item.frozenFunds > 0 ? item.frozenFunds : 0 | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">交易手续费</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(Number(myAccount.procedureFee).toFixed(2))
                        | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">交收服务费</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(Number(myAccount.deliveryFee).toFixed(2))
                        | toThousands
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">可出金额</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">
                    {{
                      Number(myAccount.canBeMargin) > 0
                        ? myAccount.canBeMargin
                        : 0 | priceFormat1
                    }}
                  </p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">出金合计</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">{{ myAccount.outGold | priceFormat1 }}</p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">入金合计</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">{{ myAccount.inGold | priceFormat1 }}</p>
                </div>
              </div>
              <div class="zjItem" v-if="item.marketId == 28">
                <div>
                  <img
                    src="../../assets/images/asset/zhzj.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="right-Style">
                  <p class="zhName">欠款</p>
                  <p class="lineZj"></p>
                  <p class="zhzj">{{ myAccount.oweMoney | priceFormat1 }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(item, ind) in BaseMarkets"
              :key="ind"
              :label="item.accountName"
              :name="String(item.marketId)"
            >
              <div class="outBox">
                <div class="accBox">
                  <p class="items" v-if="item.marketId != 28">
                    账户资金:
                    <span>{{
                      item.balance - item.frozenFunds > 0
                        ? item.balance - item.frozenFunds
                        : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId != 28">
                    冻结资金:
                    <span>{{
                      item.frozenFunds > 0 ? item.frozenFunds : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    当前账户权益:
                    <span>{{
                      Number(newBalance) > 0
                        ? Number(Number(newBalance).toFixed(2))
                        : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    资金安全率:
                    <span
                      >{{
                        Number(myAccount.risk) > 99.9999 ||
                        Number(myAccount.occupyMargin) === 0
                          ? "9999.99"
                          : (Number(myAccount.risk) * 100).toFixed(2)
                      }}%</span
                    >
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    上日权益:
                    <span>{{
                      Number(myAccount.lastBalance) > 0
                        ? Number(Number(myAccount.lastBalance).toFixed(2))
                        : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    订货盈亏:
                    <span>{{ myAccount.floatPl | priceFormat }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    转让盈亏:
                    <span>{{ myAccount.transferClosePl | priceFormat }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    交收盈亏:
                    <span>{{ myAccount.deliveryClosePl | priceFormat }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    可用资金:
                    <span>{{
                      Number(myAccount.avilibleMargin) > 0
                        ? Number(Number(myAccount.avilibleMargin).toFixed(2))
                        : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    占用履约订金:
                    <span>{{
                      Number(Number(myAccount.occupyMargin).toFixed(2))
                        | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    冻结资金:
                    <span>{{
                      item.frozenFunds > 0 ? item.frozenFunds : 0 | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    交易手续费:
                    <span>{{
                      Number(Number(myAccount.procedureFee).toFixed(2))
                        | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    交收服务费:
                    <span>{{
                      Number(Number(myAccount.deliveryFee).toFixed(2))
                        | toThousands
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    可出金额:
                    <span>{{
                      Number(myAccount.canBeMargin) > 0
                        ? myAccount.canBeMargin
                        : 0 | priceFormat1
                    }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    出金合计:
                    <span>{{ myAccount.outGold | priceFormat1 }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    入金合计:
                    <span>{{ myAccount.inGold | priceFormat1 }}</span>
                  </p>
                  <p class="items" v-if="item.marketId == 28">
                    欠款:
                    <span>{{ myAccount.oweMoney | priceFormat1 }}</span>
                  </p>
                </div>
               
              </div>
            </el-tab-pane> -->
          <!-- <span class="huazhuan">划转</span> -->
          <!-- <el-button type="primary" class="huazhuan" plain @click="goCellTrans(item)">划转</el-button> -->
          <!-- </el-tabs> -->
          <transfer-com ref="transferCom" :activeFlag="activeId" />
          <!-- <span class="report" @click="reportShow()">财务记录</span> -->
        </div>
        <div class="accTabs" v-else>
          <h2 style="font-size: 18px;color: red;font-weight:700">
            请先进行信息申报!
          </h2>
        </div>
      </div>
    </section>

    <Footer />

    <!-- 划转弹窗 -->
    <!-- <transfer-dialog ref="transferDialog"  @reLoad="showRepsss"/> -->
  </div>
</template>

<script>
/* global http protocol protocolFwd*/
import transferCom from "./component/transferCom";
// import transferDialog from './component/transferDialog'
import Footer from "../../components/base/footer/footerZYQ";
import { mapGetters } from "vuex";

export default {
  components: {
    transferCom,
    // transferDialog,
    Footer,
  },
  data() {
    return {
    item: {

    },
      myAccount: {
        accountId: 0,
        avilibleMargin: 0,
        balance: 0,
        canBeMargin: 0,
        closePl: 0,
        createTime: 0,
        firmId: 0,
        frozenFunds: 0,
        lastBalance: 0,
        occupyMargin: 0,
        procedureFee: 0,
        risk: 0,
        swapsFee: 0,
        symbolId: 0,
        updateTime: 0,
        deliveryFee: 0,
        transferClosePl: 0,
        deliveryClosePl: 0,
        floatPl: 0,
        inGold: 0,
        outGold: 0,
        oweMoney: 0,
      },
      // 计算保存值
      myAccountCom: {
        accountId: 0,
        avilibleMargin: 0,
        balance: 0,
        canBeMargin: 0,
        closePl: 0,
        createTime: 0,
        firmId: 0,
        frozenFunds: 0,
        lastBalance: 0,
        occupyMargin: 0,
        procedureFee: 0,
        risk: 0,
        swapsFee: 0,
        symbolId: 0,
        updateTime: 0,
        deliveryFee: 0,
        transferClosePl: 0,
        deliveryClosePl: 0,
        floatPl: 0,
        inGold: 0,
        outGold: 0,
        oweMoney: 0,
      },
      TradeClosepl: {
        firmId: 0,
        sumClosePl: 0,
        tcTime: 0,
        tcTimeUp: 0,
        tradeId: 0,
        tradeTime: 0,
        tradeTimeUp: 0,
        updatetime: 0,
      },
      runloopTimer: null,
      activeName: this.$route.query.command ? this.$route.query.command : "28",
      BaseMarkets: [],
      activeId: null, // 如是需要下拉选择activeId:null
      totalAssets: "0.00",
      approveState: "", // 认证状态
    };
  },
  created() {
    this.runloopTimer = null;
    window.clearInterval(this.runloopTimer);
    if (localStorage.getItem("FirmIdList_w")) {
      var arr = JSON.parse(localStorage.getItem("FirmIdList_w"));
      if (arr.length > 0) {
        sessionStorage.setItem("marketId_w", arr[0].marketId);
      } else {
        sessionStorage.setItem("marketId_w", null);
      }
    }
  },
  beforeDestroy() {
    if (this.runloopTimer) {
      window.clearInterval(this.runloopTimer);
      this.runloopTimer = null;
    }
  },
  mounted() {
    this.findAccountByUserId();
    this.getIdentificationStatus();
    this.queryTradeClosepl();
    // this.queryBankSignInfo();
  },
  computed: {
    ...mapGetters([
      "myassetDataGetter",
      "marketStatusGetter",
      "h_sumClosePl",
      "userAssetGetter",
    ]),
    newBalance() {
      if (this.marketStatusGetter.status == "C") {
        return this.myAccount.balance;
      }
      return String(Number(this.myAccount.balance) + Number(this.h_sumClosePl));
    },
  },
  watch: {
    $route: {
      handler(val, oldval) {
        this.activeName = val.query.command;
        this.activeId = Number(val.query.command);
      },
    },
    userAssetGetter() {
      this.myAccount = this.userAssetGetter;
      this.myAccountCom = this.userAssetGetter;
    },
  },
  methods: {
    getMyAccount() {
      if (this.userAssetGetter) {
        this.isSuccess = true;
        this.myAccount = this.userAssetGetter;
        this.myAccountCom = this.userAssetGetter;
      }
    },
    // 查询当前交易id
    queryTradeClosepl() {
      if (
        sessionStorage.getItem("sessionStr") &&
        sessionStorage.getItem("userId")
      ) {
        http.postFront(protocol.param_queryTradeClosepl).then((response) => {
          const { code, value } = response.data;
          if (Number(code) === 0) {
            if (value) {
              if (value.contentSystem) {
                this.$store.commit("setMarketStatus", value.contentSystem);
              }
              this.$store.commit("setNewtradeId", value.tradeId); // 交易表主键
              this.$store.commit(
                "setH_deliveryOrderTime",
                value.deliveryOrderTime
              ); // 交收订单更新时间
              this.$store.commit("setH_holdTime", value.holdTime); // 持仓汇总更新时间
              this.$store.commit("setH_orderTime", value.orderTime); // 委托更新时间
              this.$store.commit("setH_outfundTime", value.outfundTime); // 出金阈值更新时间
              this.$store.commit("setH_warehouseTime", value.warehouseTime); // 交收仓单更新时间
              if (value.mineAsset) {
                this.$store.commit("setUserAsset", value.mineAsset);

                if (
                  value.mineAsset.avilibleMargin &&
                  Number(value.mineAsset.avilibleMargin) < 0
                ) {
                  this.$store.commit("setH_avilibleMargin", 0); // 可用资金
                } else {
                  this.$store.commit(
                    "setH_avilibleMargin",
                    value.mineAsset.avilibleMargin
                  ); // 可用资金
                }
                if (value.mineAsset.floatPl) {
                  this.$store.commit(
                    "setH_sumClosePl",
                    value.mineAsset.floatPl
                  ); // 浮动盈亏
                } else {
                  this.$store.commit("setH_sumClosePl", 0); // 浮动盈亏
                }
                if (value.mineAsset.updateTime) {
                  this.$store.commit(
                    "setH_accountTime",
                    value.mineAsset.updateTime
                  ); // 资金更新时间
                } else {
                  this.$store.commit("setH_accountTime", 0); // 资金更新时间
                }
              } else {
                var obj = {
                  accountId: null,
                  avilibleMargin: 0,
                  balance: 0,
                  canBeMargin: 0,
                  closePl: 0,
                  createTime: 0,
                  deliveryClosePl: 0,
                  deliveryFee: 0,
                  floatPl: 0,
                  frozenFunds: 0,
                  inGold: 0,
                  lastBalance: 0,
                  occupyMargin: 0,
                  outGold: 0,
                  procedureFee: 0,
                  risk: 0,
                  swapsFee: 0,
                  symbolId: null,
                  transferClosePl: 0,
                  updateTime: 0,
                  firmId: 0,
                };
                this.$store.commit("setUserAsset", obj);
              }
              this.runloopQueryTradeClosepl();
            } else {
              this.runloopQueryTradeClosepl();
            }
          } else {
            this.runloopQueryTradeClosepl();
          }
        });
      } else {
        this.runloopQueryTradeClosepl();
      }
    },
    // 心跳
    runloopQueryTradeClosepl() {
      // const that = this;
      this.runloopTimer = setInterval(
        () => {
          // if (this.isOpenN) {
          if (
            sessionStorage.getItem("sessionStr") &&
            sessionStorage.getItem("userId") &&
            this.BaseMarkets.length > 0 &&
            this.BaseMarkets[0].marketId == 28
          ) {
            http
              .postFront(protocol.param_queryTradeClosepl)
              .then((response) => {
                console.log(this.heartbeat, "公告时间");
                if (response.data) {
                  const { code, value } = response.data;
                  if (Number(code) === 0) {
                    if (value) {
                      if (value.contentSystem) {
                        this.$store.commit(
                          "setMarketStatus",
                          value.contentSystem
                        );
                      }
                      this.$store.commit("setNewtradeId", value.tradeId); // 交易表主键
                      this.$store.commit(
                        "setH_deliveryOrderTime",
                        value.deliveryOrderTime
                      ); // 交收订单更新时间
                      this.$store.commit("setH_holdTime", value.holdTime); // 持仓汇总更新时间
                      this.$store.commit("setH_orderTime", value.orderTime); // 委托更新时间
                      this.$store.commit("setH_outfundTime", value.outfundTime); // 出金阈值更新时间
                      this.$store.commit(
                        "setH_warehouseTime",
                        value.warehouseTime
                      ); // 交收仓单更新时间
                      if (value.mineAsset) {
                        this.$store.commit("setUserAsset", value.mineAsset);

                        if (
                          value.mineAsset.avilibleMargin &&
                          Number(value.mineAsset.avilibleMargin) < 0
                        ) {
                          this.$store.commit("setH_avilibleMargin", 0); // 可用资金
                        } else {
                          this.$store.commit(
                            "setH_avilibleMargin",
                            value.mineAsset.avilibleMargin
                          ); // 可用资金
                        }
                        if (value.mineAsset.floatPl) {
                          this.$store.commit(
                            "setH_sumClosePl",
                            value.mineAsset.floatPl
                          ); // 浮动盈亏
                        } else {
                          this.$store.commit("setH_sumClosePl", 0); // 浮动盈亏
                        }
                        if (value.mineAsset.updateTime) {
                          this.$store.commit(
                            "setH_accountTime",
                            value.mineAsset.updateTime
                          ); // 资金更新时间
                        } else {
                          this.$store.commit("setH_accountTime", 0); // 资金更新时间
                        }
                      } else {
                        var obj = {
                          accountId: null,
                          avilibleMargin: 0,
                          balance: 0,
                          canBeMargin: 0,
                          closePl: 0,
                          createTime: 0,
                          deliveryClosePl: 0,
                          deliveryFee: 0,
                          floatPl: 0,
                          frozenFunds: 0,
                          inGold: 0,
                          lastBalance: 0,
                          occupyMargin: 0,
                          outGold: 0,
                          procedureFee: 0,
                          risk: 0,
                          swapsFee: 0,
                          symbolId: null,
                          transferClosePl: 0,
                          updateTime: 0,
                          firmId: 0,
                        };
                        this.$store.commit("setUserAsset", obj);
                      }
                    } else {
                    }
                  }
                }
              });
          }
        },
        window.g.APP_RUNLOOP_TIME ? window.g.APP_RUNLOOP_TIME : 60000
      );
    },
    // 划转弹窗
    showRepsss(val) {
      this.findAccountByUserId();
    },
    // tabs 点击
    handleClick(tab) {
      this.item = tab
      this.activeId = Number(tab.name);
    },
    goTransfer() {
      // this.$router.push('/transfer')
      if (this.approveState == 1) {
        this.$router.push("/transfer");
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
    },
    goInfo() {
      if (this.approveState == 1) {
        this.$router.push("/infoReporing");
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
      // this.$router.push("/infoReporing");
    },
    // 信息申报状态查询
    query_param_findTrader() {
      http.postFront(protocolFwd.param_findTrader).then((response) => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value) {
            if (Number(value.status) === 1) {
              this.$router.push("/sign");
            } else {
              this.$EL_MESSAGE("请先进行信息申报");
            }
          } else {
            this.$EL_MESSAGE("请先进行信息申报");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goSign() {
      if (this.approveState == 1) {
        // this.$router.push("/sign");
        this.query_param_findTrader();
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
      // this.$router.push("/sign");
    },
    goOutInt() {
      if (this.approveState == 1) {
        this.$router.push("/StateFunding");
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
      // this.$router.push("/StateFunding");
    },
    goSignList() {
      if (this.approveState == 1) {
        this.$router.push("/signList");
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
      // this.$router.push("/signList");
    },
    // 财务记录
    reportShow() {
      if (this.approveState === 1) {
        this.$router.push("/financialRecord");
      } else {
        this.$EL_MESSAGE({
          message: "请先前往用户-身份认证页面进行信息认证!",
          type: "warning",
        });
      }
    },
    goCellTrans(item) {
      this.$refs.transferDialog.showDialog(item);
    },
    // 查询当前账户
    findAccountByUserId() {
      http.postFront(protocol.param_findAccountByUserId).then((response) => {
        const { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value && value.length != 0) {
            this.BaseMarkets = value;
            // this.activeName = response.data.value[0].marketId
            let sum = 0;
            for (let i = 0; i < this.BaseMarkets.length; i++) {
              sum += this.BaseMarkets[i].balance;
            }
            this.activeName = String(this.BaseMarkets[0].marketId);
            this.activeId = this.BaseMarkets[0].marketId;
            this.item = this.BaseMarkets[0]
            // if(sum == 0){
            // this.totalAssets = '0.00'
            // }else{
            if (Number(sum) > 0) {
              this.totalAssets = sum;
            }
            // }
          } else {
            this.BaseMarkets = [];
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 认证状态查询
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then((response) => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            // 审核状态（0:未审核 1:已认证 2:已驳回）
            if (value) {
              this.approveState = value.auditStatus;
              // 存储认证信息
              sessionStorage.setItem("auditName", value.personName);
              sessionStorage.setItem("auditType", value.auditType);
              sessionStorage.setItem("auditObj", JSON.stringify(value));
            } else {
              this.approveState = "";
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 查询银行签到签退
    queryBankSignInfo() {
      http.postFront(protocolFwd.param_queryBankSignInfo).then((response) => {
        const { code, value } = response.data;
        if (Number(code) == 0) {
          value.forEach((element) => {
            if (Number(element.bankId) === 22) {
              sessionStorage.setItem("bankStatusFlag", element.bankStatusFlag);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import url(); 引入公共css类
.mainAccout {
  height: 200px;
  background: url("../../assets/images/asset/zc.png") 100% 100% no-repeat;
  background-size: cover;
  // background-color: #1b2945;
  display: flex;
  align-items: center;
}
.centerBox {
  width: 1200px;
  margin: 0 auto;
  height: 88px;
  display: flex;
  justify-content: space-between;
  .leftMain {
    .title {
      color: #ccd7e9;
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 20px;
    }
    .num {
      font-weight: 700;
      color: #ffffff;
    }
  }
  .rightMain {
    line-height: 88px;
    /deep/ .mainBtn {
      font-size: 16px !important;
      color: #ffffff !important;
      background: #80a0cf;
      border-radius: 24px;
      border: none;
    }
  }
}

.account {
  width: 100%;
  background: #fff;
  min-height: 850px;
  padding-top: 16px;
  margin-top: -26px;
  border-radius: 28px 28px 0 0;
  .wrapper {
    margin: 0 auto;
    width: 1200px;
    .tabsStyle {
      // display: flex;
      width: 100%;

      // padding-bottom: 20px;
      // border-bottom: 1px solid #E3E3E3;
      .zjWrap {
        display: flex;
        width: 1200px;
        margin-bottom: 23px;
        border-bottom: 1px solid #eee;
      }
      .zjStyle {
        display: flex;
        flex-wrap: wrap;
        .zjItem {
          display: flex;
          justify-content: space-between;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #e6ecf6;
          padding: 10px 20px;
          margin-right: 23px;
          img {
            width: 50px;
          }
        }
        .lineZj {
          width: 100%;
          height: 1px;
          background-color: #e6ecf6;
          margin: 10px 0;
        }
        .right-Style {
          padding-left: 20px;
        }
        .zhName {
          font-family: Source Han Sans SC;
          font-weight: 500;
          font-size: 16px;
          color: #0f41a0;
        }
        .zhzj {
          font-family: DINPro;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
        }
      }
      .tabStyle {
        width: 150px;
        position: relative;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }
      .titleCs {
        font-family: Source Han Sans SC;
        font-weight: 500;
        font-size: 18px;
        color: #a1acbe;
        text-align: center;
        padding-bottom: 15px;
      }
      .activeItemStyle {
        color: #124daa;
      }
      .line {
        display: inline-block;
        width: 100%;
        height: 2px;
        background: #124daa;
      }
    }
    .activeItemStyle::before {
      position: absolute;
      bottom: 2px;
      left: calc(50% - 8px);
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 4px solid #124daa;
    }
  }
}
.accTabs {
  position: relative;
  .report {
    position: absolute;
    right: 0;
    top: 14px;
    font-size: 14px;
    color: #1989fa;
    cursor: pointer;
  }
  .outBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .huazhuan {
      margin: 22px 10px 0 0;
      font-size: 16px;
      line-height: 30px;
      padding: 0 16px;
      border-radius: 4px;
      border: 1px solid #1989fa;
    }
  }
  .accBox {
    // width: 400px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    // justify-content: space-between;
    .items {
      width: 25%;
      color: #7f8790;
      // display: inline-block;
      span {
        color: #333;
        font-size: 14px;
        line-height: 22px;
        vertical-align: middle;
        margin-left: 15px;
        font-weight: 700;
      }
      margin-top: 8px;
      // line-height: 28px;
      align-items: center;
    }
  }
}

// 组件内修改样式
/deep/ .el-tabs__item {
  cursor: pointer;
  // color: #1c242c;
  font-size: 18px !important;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #a1acbe;
}
/deep/ .el-tabs__item.is-active {
  color: #124daa;
}
/deep/ .el-tabs__active-bar {
  background-color: #124daa;
}
/deep/ .el-tabs__item.is-active::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #333;
}
/deep/ .el-tabs__content {
  // height: 860px!important;
}
</style>
